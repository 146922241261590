import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectElementFromAnywhere} from "../../reducers/dataSelectors";
import {dataLoadFifthQuery, dataUpdateTuneTargetValue, dataUpdateValue, dataLoadTuneTargets} from "../../reducers/dataActions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import {logMessage} from "../../lib/logging";
import {InvalidElement} from "../data-input/InvalidElement";
import {settingsSetClientEvent, settingsSetEventUID} from "../../reducers/settingsActions";

export const DefineTargetsCheckboxElement = ({
                                                 parameterName = 'unknown',
                                                 query = 'defineTargets',
                                                 classOverrides = '',
                                                 updateTuneTarget = false
                                             }) => {

    const myElement = useSelector(state => selectElementFromAnywhere(state, parameterName));
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const newValue = event.target.checked;
        switch (myElement.widgetType) {
            case 'guiTypeCheckbox': {
                dispatch(settingsSetEventUID({eventUID: myElement.parameter}))
                dispatch(settingsSetClientEvent({clientEvent: 'editTarget'}))
                dispatch(dataUpdateValue({
                    parameter: myElement.parameter, value: newValue.toString()
                }));

                if (updateTuneTarget) {
                    const tuneTargetValue = newValue ? '75' : '25';
                    dispatch(dataUpdateTuneTargetValue({
                        parameter: myElement.parameter, value: tuneTargetValue
                    }));
                }

                dispatch(dataLoadFifthQuery());
                // MDS
                //dispatch(dataLoadTuneTargets())
                break;
            }
            default: {
                logMessage(`widgetType not implemented in handleChange: ${myElement.widgetType}`);
                break;
            }
        }
    };

    const renderField = (item) => {
        return <Row className={'align-items-center my-2'}>
            <Col>
                <Form.Check className={classOverrides}
                            type="checkbox"
                            id={`form1.${item.parameter}`}
                            label={item.displayText}
                            checked={item.value === "true"}
                            onChange={handleChange}
                />
            </Col>
        </Row>;
    };

    if (myElement === undefined) return <InvalidElement name={parameterName} useContainer={false}/>;

    return renderField(myElement);
};
