import {logMessage} from "../lib/logging";

export const selectDataLoading = state => state.data.loading;
export const selectDataSelectedAddress = state => state.data.selectedAddress;
export const selectDataUserData = state => state.data.userData;
export const selectDataFirstQuery = state => state.data.firstQuery;

export const selectDataAttrs = state => state.data.attrs;
export const selectDataScene = state => state.data.scene;
export const selectDataError = state => state.data.error;
export const selectDataLoaded = state => state.data.loaded;
export const selectDataDefineTargets = state => state.data.defineTargets;
export const selectDataEnergyAnalysis = state => state.data.energyAnalysis;
export const selectDataSecondQuery = state => state.data.secondQuery;
export const selectDataTuneTargets = state => state.data.tuneTargets;
export const selectDataMeasures = state => state.data.measures;
export const selectDataScenario = state => state.data.scenario;

export const selectElementFromFirstQuery = (state, parameter) => {
    return selectDataFirstQuery(state).find(itm => itm.parameter === parameter);
};

export const selectElementFromSecondQuery = (state, parameter) => {
    return selectDataSecondQuery(state).find(itm => itm.parameter === parameter);
};

export const selectElementFromDefineTargets = (state, parameter) => {
    return selectDataDefineTargets(state).find(itm => itm.parameter === parameter);
};

export const selectElementFromTuneTargets = (state, parameter) => {
    return selectDataTuneTargets(state).find(itm => itm.parameter === parameter);
};

/**
 * @deprecated use selectElementFromAnywhere instead without query param
 */
export const selectElementFromQuery = (state, parameter, query) => {
    if (query === 'first') return selectElementFromFirstQuery(state, parameter);
    if (query === 'second') return selectElementFromSecondQuery(state, parameter);
    if (query === 'defineTargets') return selectElementFromDefineTargets(state, parameter);
    return undefined;
};

/**
 * selects the element with the matching parameter from the state, returns undefined if not found.
 * Searches element in the following order: firstQuery, secondQuery, DefineTargets
 * @param state current state
 * @param parameter parameter to be loaded
 * @returns {*} element or undefined if not found
 */
export const selectElementFromAnywhere = (state, parameter) => {
    let itm = selectElementFromFirstQuery(state, parameter);
    if (!itm) itm = selectElementFromSecondQuery(state, parameter);
    if (!itm) itm = selectElementFromDefineTargets(state, parameter);
    // if (!itm) logMessage(`element ${parameter} not found in collections`);
    return itm;
};

/**
 * Checks if the condition string of an element is met by querying the value of the underlying element
 * @param condition condition string
 * @param state current state
 * @returns {boolean} true if condition is met otherwise false
 */
const handleCondition = (condition, state) => {
    let condOperator = '';
    let condVerify = () => {
    };
    if (condition === "_site_userType=false") {
        return true;
    }
    if (condition.includes('?')) {
        // define Value Condition
        condOperator = '?';
        condVerify = (itm, targetValue) => itm && (itm.defineValue === targetValue);

    } else if (condition.includes('!')) {
        // value not equal
        condOperator = '!';
        condVerify = (itm, targetValue) => itm && (itm.value !== targetValue);

    } else if (condition.includes('=')) {
        // value equals
        condOperator = '=';
        condVerify = (itm, targetValue) => itm && (itm.value === targetValue);
    } else {
        logMessage(`unknown condition type ${condition}`);
    }
    if (condOperator !== '') {
        const condSplit = condition.split(condOperator);
        const condKey = condSplit[0];
        const condValue = condSplit[1];

        const condElement = selectElementFromAnywhere(state, condKey);
        return condVerify(condElement, condValue);
    }
    return false;
};

/**
 * Returns the element, if all of its conditions are met, otherwise returns undefined
 * @param state current state
 * @param parameter parameter to load
 * @returns {undefined|*} item if met conditions or undefined
 */
export const selectConditionalElementFromAnywhere = (state, parameter) => {
    const itm = selectElementFromAnywhere(state, parameter);
    if (!itm) return undefined;
    if ((itm.conditions.length === 1) && itm.conditions[0] === null) {
        return itm;
    } else {
        let conditionResults = [];
        for (let cond of itm.conditions) {
            conditionResults.push(handleCondition(cond, state));
        }

        if (itm.conditionRelation === "none" && conditionResults.length === 1) {
            if (conditionResults[0]) {
                // console.log(`single condition for ${parameter} is true`);
                return itm;
            }
        } else if (itm.conditionRelation === "and") {
            if (conditionResults.every(x => x)) {
                // console.log(`all conditions for ${parameter} are true`);
                return itm;
            }
        } else if (itm.conditionRelation === "or") {
            if (conditionResults.some(x => x)) {
                // console.log(`one condition for ${parameter} is true`);
                return itm;
            }
        }
        // logMessage(`conditions for ${parameter} failed`);
        return undefined;
    }
};

export const selectDataActiveMeasures = state => {
    //return state.data.measures.filter(itm => itm.subMeasure[0].selection === '2' || itm.subMeasure[0].selection === '1');
    // MDS:
    return state.data.measures
    .filter(itm => itm.subMeasure[0].selection === '2' || itm.subMeasure[0].selection === '1')
    .map(itm => {
        // Check if the measure is Photovoltaik
        if (itm.parameter === '_pvs') {
            const subMeasure = itm.subMeasure[0];
            const subOption = subMeasure.subOption.find(opt => opt.uid === subMeasure.value);

            if (subOption) {
                // Extract nominalPower from displayText (e.g., "Aufdach-Anlage 10 kW")
                const match = subOption.displayText.match(/(\d+)\s*kW/);
                const nominalPower = match ? parseInt(match[1], 10) : 0;

                // Recalculate grossInvestment and netSavings
                const newGrossInvestment = (12072 + nominalPower * 1646).toFixed(2);
                const newNetSavings = 230 * nominalPower;

                // Generate timelineNetSavings with 30 entries ±5%
                const generateTimelineNetSavings = (baseValue) =>
                    Array.from({ length: 30 }, () => {
                        const randomFactor = 1 + (Math.random() * 0.1 - 0.05); // ±5%
                        return (baseValue * randomFactor).toFixed(2);
                    });

                const newTimelineNetSavings = generateTimelineNetSavings(newNetSavings);

                const newSubsidies = 380 * nominalPower;
                const newTaxSavings = 0.2 * (newGrossInvestment - newSubsidies)

                // Return a new itm with updated subOption cost
                return {
                    ...itm,
                    subMeasure: [
                        {
                            ...subMeasure,
                            subOption: subMeasure.subOption.map(opt =>
                                opt.uid === subMeasure.value
                                    ? {
                                        ...opt,
                                        cost: {
                                            ...opt.cost,
                                            grossInvestment: newGrossInvestment,
                                            netSavings: newNetSavings,
                                            timelineNetSavings: newTimelineNetSavings,
                                            subsidies: newSubsidies,
                                            taxSavings: newTaxSavings,
                                            netInvestment: newGrossInvestment - newSubsidies
                                        }
                                    }
                                    : opt
                            )
                        }
                    ]
                };
            }
        }

        // Check if the measure is _aux
        if (itm.parameter === '_aux') {
            const subMeasure = itm.subMeasure[0];
            const newAuxNetSavings = state.data.attrs.buildingGroundArea * 6.18;

            // Generate timelineNetSavings with 30 entries ±5%
            const generateTimelineNetSavings = (baseValue) =>
                Array.from({ length: 30 }, () => {
                    const randomFactor = 1 + (Math.random() * 0.1 - 0.05); // ±5%
                    return (baseValue * randomFactor).toFixed(2);
                });

            

            // Update timelineNetSavings for all subOptions
            const updatedSubOptions = subMeasure.subOption.map(opt => ({
                ...opt,
                cost: {
                    ...opt.cost,
                    timelineNetSavings: generateTimelineNetSavings(newAuxNetSavings)
                }
            }));

            //console.log(updatedSubOptions)
            // Return a new itm with updated subMeasure
            return {
                ...itm,
                subMeasure: [
                    {
                        ...subMeasure,
                        subOption: updatedSubOptions
                    }
                ]
            };
        }

        // Return unmodified measure if not PV or AUX
        return itm;
    });


    };
    

const includeOnlySelectedOption = (item) => {
    const selectedOptionId = item.subMeasure[0].value;
    let selectedOption = undefined;
    if (selectedOption !== '') {
        selectedOption = item.subMeasure[0].subOption.find(op => op.uid === selectedOptionId);
    }
    return {
        ...item,
        subMeasure: [
            {
                ...item.subMeasure[0],
                subOption: [
                    selectedOption
                ]
            }
        ]
    };
};

const formatItemForYearSortable = (item) => {
    const selectedOption = item.subMeasure[0].value;
    let selectedOptionName = '';
    if (selectedOption !== '') {
        selectedOptionName = item.subMeasure[0].subOption.find(op => op.uid === selectedOption).displayText;
    }
    return {
        id: item.uid,
        name: item.displayText,
        option: selectedOptionName
    };
};

/**
 * Returns all active measures of a specific year, or of all years after if includeAllAfter is set.
 * map the objects into suitable Sortable format if mapForSortable is set
 * @param state current state
 * @param year year the measures should be filtered on
 * @param includeAllAfter if true, all measures from year and after will be returns, otherwise exact year has to match
 * @param mapForSortable if true, objects are mapped to suitable sortable structure
 * @returns {*} list of measure objects
 */
export const selectDataActiveMeasuresOfYear = (state, year, includeAllAfter = false, mapForSortable = true) => {
    const allActiveMeasures = selectDataActiveMeasures(state);
    let items = [];
    if (includeAllAfter) {
        items = allActiveMeasures.filter(itm => Number(itm.implement['initialYear']) >= Number(year));
    } else {
        items = allActiveMeasures.filter(itm => itm.implement['initialYear'] === year);
    }

    if (mapForSortable) return items.map(formatItemForYearSortable);
    return items.map(includeOnlySelectedOption);
};

/**
 * Selects all Measures before a certain year
 * @param state current state
 * @param year year of which the measures are to be filtered on, non-inclusive
 * @param mapForSortable if true, objects are mapped to suitable sortable structure
 * @returns {*} list of measure objects
 */
export const selectDataActiveMeasuresBeforeYear = (state, year, mapForSortable = true) => {
    const allActiveMeasures = selectDataActiveMeasures(state);
    return allActiveMeasures.filter(itm => Number(itm.implement['initialYear']) < Number(year));
};

