import React, {useEffect} from 'react';
import Container from "react-bootstrap/Container";
import {NavBreadcrumb} from "../layout/NavBreadcrumb";
import {PageTitle} from "../layout/PageTitle";
import {useSelector} from "react-redux";
import {selectDataLoading, selectDataSelectedAddress} from "../../reducers/dataSelectors";
import {useNavigate} from "react-router";
import {SortableYearListComponent} from "../measures/SortableYearListComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {MeasureSavingsRow} from "../measures/MeasureSavingsRow";

export const MeasurePlanning = () => {
    const selectedAddress = useSelector(selectDataSelectedAddress);
    const loading = useSelector(selectDataLoading);
    const navigate = useNavigate();

    const maxElements = 6; // TODO: move to central CONFIG

    useEffect(() => {
        if (selectedAddress === undefined) {
            console.log('no address selected - redirect to auswahl');
            navigate('/auswahl');
        }
    }, [selectedAddress, loading, navigate]);

    return <>
        <NavBreadcrumb/>
        <Container>
            <PageTitle>Massnahmenplanung</PageTitle>
            <SortableYearListComponent maxElements={maxElements} />
            {/*<MeasureSavingsList maxElements={maxElements}/>*/}

            <MeasureSavingsRow showHeader={true}
                               showHeaderBorder={true}
                               maxElements={maxElements}
                               bold={true}
                               attribute={'grossInvestment'}
                               name={'Brutto Investitionen'}
                               showBottomBorder={true}
            />
            <MeasureSavingsRow showHeader={false}
                               maxElements={maxElements}
                               bold={false}
                               attribute={'subsidies'}
                               name={'Fördergelder'}
            />
            <MeasureSavingsRow showHeader={false}
                               maxElements={maxElements}
                               bold={false}
                               attribute={'taxSavings'}
                               name={'Steuerersparnis'}
            />
            <MeasureSavingsRow showHeader={false}
                               maxElements={maxElements}
                               bold={true}
                               attribute={'netInvestment'}
                               name={'Netto Investitionen'}
                               showBottomBorder={true}
            />
            {/* Rows <MeasureSavingsRow showHeader={false}
                               maxElements={maxElements}
                               bold={false}
                               attribute={'netSavings'}
                               name={'CHF Einsparung (30 Jahre)'}
            />
            <MeasureSavingsRow showHeader={false}
                               maxElements={maxElements}
                               bold={true}
                               showBottomBorder={true}
                               attribute={'CO2Savings'}
                               name={<span>CO<sub>2</sub> Einsparung (30 Jahre)</span>}
            />*/}

            <Row className={'py-5'}>
                <Col className={'d-flex justify-content-end'}>
                    <Link className={'nav-link me-3'} to="/massnahmen">
                        <Button size={'sm'} style={{'width': '200px'}}>Massnahmen</Button>
                    </Link>
                    <Link className={'nav-link me-3'} to="/finanzplanung">
                        <Button size={'sm'} style={{'width': '200px'}}>Finanzplanung</Button>
                    </Link>
                    {/*<Link className={'nav-link me-3'} to="/fördergelder">
                        <Button size={'sm'} style={{'width': '200px'}}>Details Fördergelder</Button>
                    </Link>
                    {/*<Link className={'nav-link'} to="/disclaimer">*/}
                    {/*    <Button size={'sm'} style={{'width': '200px'}}>Disclaimer</Button>*/}
                    {/*</Link>*/}
                    {/*<Link className={'nav-link'} to="/steuerersparnis">
                        <Button size={'sm'} style={{'width': '200px'}}>Details Steuerersparnis</Button>
                    </Link>*/}
                </Col>
            </Row>
        </Container>
    </>;
};